<template>
  <div class="user">
    <div class="form_info">
      <el-form ref="form" :model="queryInfo" label-width="80px">
        <div class="form">
          <div class="comble_txt">
            <el-form-item label="所在地区:" prop="">
              <el-select v-model="queryInfo.province" placeholder="请选择省份" @change="citychange">
                <el-option :value="''" label="全部"></el-option>
                <el-option
                  v-for="item in operatingList"
                  :key="item.id"
                  :label="item.name"
                  :value="item.name"
                >
                </el-option>
              </el-select>
              <div style="display: inline-block; width: 10px"></div>
              <el-select v-model="queryInfo.city" placeholder="请选择地区" @change="search">
                <el-option :value="''" label="全部"></el-option>
                <el-option
                  v-for="item in operatingLists"
                  :key="item.id"
                  :label="item.name"
                  :value="item.name"
                >
                </el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="企业类别:" prop="">
              <el-radio-group v-model="queryInfo.enterpriseCategory" @change="search">
                <el-radio-button label="">全部 </el-radio-button>
                <el-radio-button v-for="item in codelist" :key="item.codeId" :label="item.codeName">
                </el-radio-button>
              </el-radio-group>
            </el-form-item>

            <el-form-item label=" " label-width="0px">
              <div class="input-with">
                <div>
                  <el-button type="primary" plain icon="el-icon-plus" @click="handleAdd"
                    >新增</el-button
                  >

                  <!-- <el-button >批量导出</el-button> -->
                </div>
                <div>
                  <el-input
                    placeholder="请输入企业名称"
                    v-model="queryInfo.companyName"
                    class="input-with-select"
                  >
                    <el-button class="seach" slot="append" @click="search()">搜索</el-button>
                  </el-input>
                </div>
              </div>
            </el-form-item>
          </div>
        </div>
      </el-form>
    </div>

    <div class="tab_txt" v-loading="loading">
      <span style="position: relative; top: -10px">当前条件下共查处{{ total }}家企业</span>
      <div class="storeCenter_item" v-for="(item, index) in listData" :key="index">
        <div class="item_checkbox">
          <el-checkbox @change="latechange" v-model="item.checked1" label=""></el-checkbox>
        </div>
        <div class="storeCenter_item_top">
          <div class="storeCenter_item_top_left">
            <img v-if="item.companyLogo" :src="item.companyLogo" alt="" />
            <img v-else src="../../assets/image/Group 3237.png" alt="" />
            <div class="storeCenter_item_top_left_flex">
              <div style="display: flex; align-items: center">
                <h5 style="display: inline-block; margin: 0; font-size: 18px">
                  {{ item.companyFullName }}
                </h5>
                <!-- <span style="padding: 0px 10px">{{ item.secretaryGeneralName }}</span> -->
                <div class="img_commers" style="padding: 0px 10px">
                  <div class="color_img">
                    <img src="../../assets/image/jr-icon-poeple.png" alt="" />
                  </div>
                  <span class="name"> {{ item.secretaryGeneralName }}</span>
                </div>

                <div class="img_commer" style="padding: 0px 10px">
                  <div class="color_img">
                    <img src="../../assets/image/jr-icon-commerce.png" alt="" />
                  </div>
                  <span class="name"> {{ item.chamberCommerceName }}</span>
                </div>
              </div>
              <div class="storeCenter_item_top_left_flex_tag">
                <span v-for="(o, index) in item.labelName" :key="index">
                  <span v-if="index < 3 && o != ''" class="office">{{ o }}</span>
                </span>
              </div>
              <p class="address">
                <span style="padding-left: 0px"> {{ item.legalPerson }}</span>
                <span> {{ item.companyPhone }}</span>
                <span> {{ item.province }}-{{ item.city }}</span>
                <span>
                  <a style="color: #448aff" href="">{{ item.officialWebsite }}</a>
                </span>
              </p>
            </div>
          </div>
          <div class="storeCenter_item_top_right">
            <!-- <el-button class="buttom_right" type="primary" @click="associatedClick(item)"
              >关联项目
              <span>({{ item.projectsNumber }})</span>
            </el-button> -->
            <el-button class="buttom_right" @click="UserClick(item)"
              >关联用户
              <span>({{ item.associatedUsersNumber }})</span>
            </el-button>
            <span class="edit" @click="editclick(item)">编辑</span>
            <span class="frozen" @click="delcompany(item)">删除</span>
          </div>
          <div class="Relevancy" v-if="item.enterpriseCategory == '传统企业'">
            <span>{{ item.enterpriseCategory }}</span>
          </div>
          <div class="Relevancyblue" v-else-if="item.enterpriseCategory != null">
            <span>{{ item.enterpriseCategory }}</span>
          </div>
        </div>
      </div>
      <div class="new_page">
        <el-pagination
          :current-page="queryInfo.pageNum"
          :page-sizes="[10, 30, 50]"
          :page-size="queryInfo.pageSize"
          layout="total, sizes, prev, pager, next, jumper"
          :total="total"
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
        />
      </div>
    </div>
  </div>
</template>

<script>
import { companylist, getCityAll, getCode, delcompany } from '@/api/datement'
import { getByFatherIdt, getProvince } from '@/api/demand'
const defaultQueryInfo = Object.freeze({
  pageNum: 1,
  pageSize: 10,
  companyName: null, //企业名称
  city: null, //所在地区
  companyId: null, //企业ID
  projectName: null, //项目名称
  labelName: null, //标签名称
  province: null, //所在省份
  enterpriseCategory: '' //企业类别
})
export default {
  name: 'Userment',
  data() {
    return {
      queryInfo: { ...defaultQueryInfo },
      listData: [],
      // 遮罩层
      loading: false,
      // 总条数
      total: 0,
      operatingList: [], //省
      operatingLists: [], //市
      codelist: []
    }
  },
  created() {
    this.search()
    this.incity()
    this.getCode()
  },
  methods: {
    //企业类别
    async getCode() {
      const res = await getCode({ code: '013' })
      if (res.data.resultCode == 200) {
        this.codelist = res.data.data
      }
    },
    //进去城市
    async getByFatherIdt(id) {
      const res = await getByFatherIdt({ provinceCode: id })
      if (res.data.resultCode == 200) {
        this.operatingLists = res.data.data
      }
    },
    //城市
    async incity() {
      const res = await getProvince()
      if (res.data.resultCode == 200) {
        this.operatingList = res.data.data
      }
    },
    citychange() {
      this.operatingList.forEach((o) => {
        if (this.queryInfo.province == o.name) {
          this.queryInfo.city = ''
          this.getByFatherIdt(o.code)
        }
      })
      this.search()
    },
    async search() {
      this.loading = true
      const res = await companylist(this.queryInfo)
      if (res.data.resultCode == 200) {
        this.listData = res.data.data.list
        this.total = res.data.data.total
        this.loading = false
        this.listData.forEach((item) => {
          if (item.labelName != null) {
            item.labelName = item.labelName.split(',')
            item.labelName = item.labelName.slice(0, 3)
          }
        })
      }
    },

    //删除企业
    delcompany(row) {
      const ids = row.id
      this.$alert('您是否确认删除？')
        .then(function () {
          return delcompany(ids)
        })
        .then(() => {
          this.search()
          this.$message.success('删除成功')
        })
        .catch(() => {})
    },
    //跳转新增
    handleAdd() {
      this.$router.push({
        path: '/datament/addenterprise'
      })
    },
    //编辑企业
    editclick(item) {
      this.$router.push({
        name: 'addenterprise',
        query: { id: item.id }
      })
    },
    associatedClick(item) {
      this.$router.push({
        name: 'associated',
        query: { id: item.id }
      })
    },
    //关联用户
    UserClick(item) {
      this.$router.push({
        name: 'CorrelateUser',
        query: {
          id: item.id,
          secretaryGeneralName: item.secretaryGeneralName,
          chamberCommerceName: item.chamberCommerceName
        }
      })
    },
    latechange() {},
    // 分页
    handleSizeChange(val) {
      this.queryInfo.pageSize = val
      this.search()
      // console.log(`每页 ${val} 条`)
    },
    // 分页
    handleCurrentChange(val) {
      this.queryInfo.pageNum = val
      this.search()
      // console.log(`当前页: ${val}`)
    }
  }
}
</script>

<style lang="less" scoped>
::v-deep .el-checkbox__inner::after {
  box-sizing: content-box;
  content: '';
  border: 1px solid #fff;
  border-left: 0;
  border-top: 0;
  height: 7px;
  left: 5px;
  position: absolute;
  top: 2px;
  transform: rotate(45deg) scaleY(0);
  width: 4px;
  transition: transform 0.15s ease-in 50ms;
  transform-origin: center;
}
::v-deep .el-checkbox__inner {
  display: inline-block;
  position: relative;
  border: 1px solid #dcdfe6;
  border-radius: 2px;
  box-sizing: border-box;
  width: 18px;
  height: 18px;
  background-color: #fff;
  z-index: 1;
  transition: border-color 0.25s cubic-bezier(0.71, -0.46, 0.29, 1.46),
    background-color 0.25s cubic-bezier(0.71, -0.46, 0.29, 1.46);
}
.user {
  .form_info {
    background: #ffffff;
    padding: 20px;
  }

  .tab_txt {
    margin-top: 20px;
    padding: 0px;
  }

  .span_div {
    font-size: 14px;
    color: #7c7f8e;
    margin-right: 12px;
  }

  .input-with {
    width: 100%;
    overflow: hidden;
    display: flex;
    justify-content: space-between;
    padding: 0px 10px;

    .input-with-select {
      width: 400px;
    }

    .seach {
      background-color: #448aff;
      color: #fff;
      border-radius: 0;
      border: 1px solid #448aff;
    }
  }

  .in_table {
    width: 100%;
    margin-top: 20px;

    .img_url {
      width: 42px;
      height: 42px;
      border-radius: 4px;
    }
  }

  .new_page {
    margin: 20px auto;
    width: 100%;
    text-align: center;
  }

  .storeCenter_item {
    width: 100%;
    overflow: hidden;
    margin-bottom: 15px;
    display: flex;
    align-items: center;
    border-radius: 4px;
    box-sizing: border-box;
    position: relative;
    .item_checkbox {
      line-height: 36px;
      margin: 0px 10px;
    }
    .Relevancy {
      position: absolute;
      width: 83px;
      height: 25px;
      top: 15px;
      left: 9px;
      background: #ff7d18;
      border-radius: 10px 15px 15px 0px;
      font-size: 12px;
      text-align: center;
      line-height: 25px;
      color: #ffffff;
    }
    .Relevancyblue {
      position: absolute;
      width: 83px;
      height: 25px;
      top: 15px;
      left: 9px;
      background: #146aff;
      border-radius: 10px 15px 15px 0px;
      font-size: 12px;
      text-align: center;
      line-height: 25px;
      color: #ffffff;
    }
    .storeCenter_item_top {
      width: 100%;
      display: flex;
      align-items: center;
      flex-direction: row;
      justify-content: space-between;
      box-sizing: border-box;
      padding-bottom: 14px;
      position: relative;
      background: #ffffff;
      padding: 16px 20px 10px 10px;
      .storeCenter_item_top_left {
        display: flex;
        flex-direction: row;

        img {
          width: 90px;
          height: 90px;
          border-radius: 4px;
          margin-right: 14px;
        }

        .storeCenter_item_top_left_flex {
          flex: 1;
          overflow: hidden;
          display: flex;
          flex-direction: column;
          justify-content: space-between;
          .img_commer {
            display: flex;
            border: 1px solid #146aff;
            border-radius: 39px;
            .name {
              font-size: 12px;
              line-height: 20px;
              color: #146aff;
            }
            .color_img {
              background: #146aff;
              height: 20px;
              width: 25px;
              border-radius: 20px;
              position: relative;
              left: -10px;
              img {
                width: 13px;
                height: 13px;
                position: relative;
                left: 5px;
                top: 2px;
              }
            }
          }
          .img_commers {
            display: flex;
            border: 1px solid #ff9c00;
            border-radius: 39px;
            margin: 0px 10px;
            .name {
              font-size: 12px;
              line-height: 20px;
              color: #ff9c00;
            }
            .color_img {
              background: #ff9c00;
              height: 20px;
              width: 25px;
              border-radius: 20px;
              position: relative;
              left: -10px;
              img {
                width: 13px;
                height: 13px;
                position: relative;
                left: 5px;
                top: 2px;
              }
            }
          }

          h4 {
            color: #1f2d3d;
            font-size: 26px;
            font-weight: 500;
            margin: 0;
          }

          .storeCenter_item_top_left_flex_tag {
            width: 100%;
            display: flex;
            flex-direction: row;
            align-content: center;
            padding-top: 13px;

            span {
              box-sizing: border-box;
              padding: 1px 6px;
              display: block;
              font-size: 14px;
              font-weight: 400;
              border-radius: 4px;
            }

            .office {
              border: 1px solid #559eff;
              color: #4e93fb;
            }

            .support {
              border: 1px solid #fd523f;
              color: #fd523f;
              margin-left: 12px;
            }
          }
          .address {
            font-size: 14px;
            span {
              padding: 0px 10px;
            }
          }
        }
      }
      .storeCenter_item_top_right {
        .buttom_right {
          height: 36px;
          line-height: 1px;
        }
        .edit {
          padding-left: 20px;
          color: #4e93fb;
          cursor: pointer;
        }
        .frozen {
          padding-left: 20px;
          color: #fd5469;
          cursor: pointer;
        }
      }
    }
  }
}
</style>
