import request from "@/utils/request"
//查询企业信息列表
export function companylist(query) {
    return request({
        url: "/company/list",
        method: "get",
        params:query
    })
}
//新增企业形象
export function addcompany(data) {
    return request({
        url: "/company",
        method: "post",
        data:data
    })
}
//修改
export function editcompany(data) {
    return request({
        url: "/company",
        method: "put",
        data:data
    })
}
//获取企业信息详情
export function getcompany(id) {
    return request({
        url: "/company/"+id,
        method: "get",
    
    })
}
//删除企业
export function delcompany(idS) {
    return request({
        url: "/company/"+idS,
        method: "delete",
    
    })
}
//查询企业标签
export function labelist(query) {
    return request({
        url: "/label/list",
        method: "get",
        params:query
    })
}
//新增标签
export function addlabel(data) {
    return request({
        url: "/label",
        method: "post",
        data:data
    })
}
//编辑标签
export function editlabel(data) {
    return request({
        url: "/label",
        method: "put",
        data:data
    })
}

//获取标签信息详情
export function getlabel(id) {
    return request({
        url: "/label/"+id,
        method: "get",
    
    })
}
//查询秘书长用户列表
export function getSecretaryGeneral() {
    return request({
        url: "/wechatUser/getSecretaryGeneral",
        method: "get",
    
    })
}
//查询所有商会
export function commercelist() {
    return request({
        url: "/commerce/listAll",
        method: "get",
    
    })
}
//删除标签
export function dellabel(ids) {
    return request({
        url: "/label/"+ids,
        method: "delete",
    
    })
}

//企业关联用户
export function getCorrelateUser(query) {
    return request({
        url: "/company/getCorrelateUser",
        method: "get",
        params:query
    })
}
//企业类型code
export function getCode(query) {
    return request({
        url: "/code/getCode",
        method: "get",
        params:query
    })
}

